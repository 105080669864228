import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact-form9.css'

const ContactForm9 = (props) => {
  return (
    <div className="contact-form9-contact11 thq-section-padding">
      <div className="contact-form9-max-width thq-section-max-width">
        <div className="contact-form9-section-title thq-card">
          <div className="contact-form9-content1">
            <h2 className="thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="contact-form9-text17">Contact Us</span>
                </Fragment>
              )}
            </h2>
            <p className="thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="contact-form9-text19">
                    Have a question or need assistance? Reach out to us!
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="contact-form9-content2">
          <form className="contact-form9-form thq-card">
            <div className="contact-form9-input1">
              <label htmlFor="contact-form-9-name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="contact-form-9-name"
                placeholder="Full Name"
                className="thq-input"
              />
            </div>
            <div className="contact-form9-input2">
              <label htmlFor="contact-form-9-email" className="thq-body-small">
                Email
              </label>
              <input
                type="text"
                id="contact-form-9-email"
                required="true"
                placeholder="Email"
                className="thq-input"
              />
            </div>
            <div className="contact-form9-container">
              <div className="contact-form9-input3">
                <label
                  htmlFor="contact-form-9-options"
                  className="thq-body-small"
                >
                  Choose one topic
                </label>
                <select
                  id="contact-form-9-options"
                  placeholder="Select one"
                  className="contact-form9-select thq-select"
                >
                  <option value="Option 1">Option 1</option>
                  <option value="Option 1">Customer Service</option>
                  <option value="Option 1">Feature request</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                </select>
              </div>
            </div>
            <div className="contact-form9-input4">
              <label
                htmlFor="contact-form-9-message"
                className="thq-body-small"
              >
                Message
              </label>
              <textarea
                id="contact-form-9-message"
                rows="3"
                placeholder="Enter your message"
                className="contact-form9-textarea thq-input"
              ></textarea>
            </div>
            <div className="contact-form9-checkbox1">
              <input
                type="checkbox"
                id="contact-form-9-check"
                checked="true"
                required="true"
                className="thq-checkbox"
              />
              <label htmlFor="contact-form-9-check" className="thq-body-small">
                I accept the Terms
              </label>
            </div>
            <button type="submit" className="thq-button-filled">
              <span className="thq-body-small">
                {props.action ?? (
                  <Fragment>
                    <span className="contact-form9-text18">Submit</span>
                  </Fragment>
                )}
              </span>
            </button>
          </form>
          <div className="contact-form9-content3 thq-card">
            <div className="thq-flex-row contact-form9-row">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="contact-form9-image thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactForm9.defaultProps = {
  heading1: undefined,
  imageSrc:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=1400',
  imageAlt: 'Contact Form Image',
  action: undefined,
  content1: undefined,
}

ContactForm9.propTypes = {
  heading1: PropTypes.element,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  action: PropTypes.element,
  content1: PropTypes.element,
}

export default ContactForm9
