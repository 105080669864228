import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Steps2 from '../components/steps2'
import ContactForm9 from '../components/contact-form9'
import Logos33 from '../components/logos33'
import Footer3 from '../components/footer3'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Rocky Electronic Systems</title>
        <meta property="og:title" content="Rocky Electronic Systems" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text10">Home</span>
          </Fragment>
        }
        link1Url="http://rockysys.com"
        link2={
          <Fragment>
            <span className="home-text11">Services</span>
          </Fragment>
        }
        link2Url="http://rockysys.com"
        link3={
          <Fragment>
            <span className="home-text12">About</span>
          </Fragment>
        }
        link3Url="http://rockysys.com"
        link4={
          <Fragment>
            <span className="home-text13">Contact</span>
          </Fragment>
        }
        link4Url="http://rockysys.com"
        page1={
          <Fragment>
            <span className="home-text14">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text15">Services</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text16">About Us</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text17">Contact</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text18">Get Started</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text19">Contact Us</span>
          </Fragment>
        }
        page1Description={
          <Fragment>
            <span className="home-text20">
              Welcome to our IT consulting firm. Explore our services and
              solutions.
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text21">
              Discover our range of services including cyber security, software
              development, database management, server infrastructure, and
              digital solutions.
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text22">
              Learn more about our company, mission, and values.
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text23">
              Get in touch with us for your IT consulting needs.
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text24">Explore Our Services</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text25">Contact Us</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text26">
              <span>
                Rocky Electronic Systems Inc. is a premier IT consulting firm
                based in Edmonton, Alberta, delivering cutting-edge solutions in
                cybersecurity, database management, server infrastructure, and
                software development. Since our founding in 2006, we have been
                dedicated to helping businesses enhance IT infrastructure,
                protect critical data, and optimize operations through
                intelligent, secure, and scalable technology solutions.
              </span>
              <br></br>
              <br></br>
              <span>
                With over 20 years of industry experience, we have earned the
                trust of leading organizations across Canada and the USA,
                serving both public and private sectors. Our esteemed clients
                include IBM Canada, ISM Canada, the Government of Alberta, the
                University of Alberta, Alberta Blue Cross, Manitoba Blue Cross,
                SaskTel Saskatchewan, Evergreen Cosmetic Edmonton, and the
                University of Nebraska–Lincoln.
              </span>
              <br></br>
              <br></br>
              <span>
                Our commitment to innovation, reliability, and security has made
                us the go-to IT partner for businesses looking to stay ahead in
                a rapidly evolving digital landscape. Whether you need
                customized IT strategies, cybersecurity reinforcement, or
                infrastructure modernization, we provide tailored solutions to
                meet your unique challenges.
              </span>
              <br></br>
              <br></br>
              <span>
                Let us help you transform technology into a strategic asset for
                your business.
              </span>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text37">
              Empower Your Business with Innovative IT Solutions
            </span>
          </Fragment>
        }
      ></Hero17>
      <Features24
        feature1Title={
          <Fragment>
            <span className="home-text38">Cyber Security</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text39">Software Development</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text40">Database Management</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text41">
              Protect your data and systems from cyber threats with our
              cutting-edge security solutions
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text42">
              Custom software development tailored to your specific business
              needs
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text43">
              Efficiently organize and manage your data with our database
              solutions
            </span>
          </Fragment>
        }
      ></Features24>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text44">Get in touch</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text45">
              Contact us today to discuss your cyber security needs and protect
              your business from potential threats.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text46">
              Ready to secure your digital assets?
            </span>
          </Fragment>
        }
      ></CTA26>
      <Steps2
        step1Title={
          <Fragment>
            <span className="home-text47">Initial Consultation</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text48">Customized Solutions</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text49">Implementation</span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text50">Ongoing Support</span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text51">
              Schedule a meeting with our team to discuss your IT needs and
              goals.
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text52">
              We tailor our services to provide personalized solutions that meet
              your specific requirements.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text53">
              Our experts will efficiently implement the agreed-upon solutions
              to enhance your IT infrastructure.
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text54">
              We offer continuous support and maintenance to ensure smooth
              operations and address any issues promptly.
            </span>
          </Fragment>
        }
      ></Steps2>
      <ContactForm9
        action={
          <Fragment>
            <span className="home-text55">Submit</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text56">
              Have a question or need assistance? Reach out to us!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text57">Contact Us</span>
          </Fragment>
        }
        imageSrc="https://images.unsplash.com/photo-1542435503-956c469947f6?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MzE5MzMyNXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      ></ContactForm9>
      <Logos33
        heading1={
          <Fragment>
            <span className="home-text58">
              Trusted by the world&apos;s best companies social proof to build
              credibility
            </span>
          </Fragment>
        }
        logo1Src="/images/logo_ibm.png"
        logo2Src="/images/logo_ism.png"
        logo3Src="/images/logo_government_of_alberta.png"
        logo4Src="/images/logo_university_of_alberta.png"
        logo5Src="/images/logo_sasktel.png"
        logo6Src="/images/logo_blue_cross_alberta.png"
        logo7Src="/images/logo_blue_cross_manitoba.png"
        logo8Src="/images/logo_evergreen_cosmetic.png"
        logo9Src="/images/logo_university_of_nebraska.png"
      ></Logos33>
      <Footer3
        link1={
          <Fragment>
            <span className="home-text59">Cyber Security</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text60">Software Development</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text61">Database Management</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text62">Server Infrastructure</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text63">Digital Solutions</span>
          </Fragment>
        }
        logoSrc="/images/logo_rocky.png"
        termsLink={
          <Fragment>
            <span className="home-text64">Terms and Conditions</span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text65">Cookies Policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text66">Privacy Policy</span>
          </Fragment>
        }
      ></Footer3>
    </div>
  )
}

export default Home
